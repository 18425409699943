body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-internal-autofill-selected,
.MuiInputBase-root input:-webkit-autofill,
.MuiInputBase-root input:-webkit-autofill:hover,
.MuiInputBase-root input:-webkit-autofill:focus,
.MuiInputBase-root input:-webkit-autofill:active,
.MuiInputBase-root input:-internal-autofill-selected {
  box-shadow: 0 0 0 1000px #424242 inset !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {
  to {
    color: #fff !important;
    background: transparent;
  }
}
